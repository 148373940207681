import {createContext, useContext, useEffect, useState} from "react";
import {
    createUserWithEmailAndPassword,
    sendEmailVerification,
    sendPasswordResetEmail,
    signInWithEmailAndPassword,
    onAuthStateChanged,
    signOut
} from "firebase/auth";
import {collection, doc, getCountFromServer, getDoc, getDocs, setDoc, updateDoc} from "firebase/firestore";
import {auth, db, mapAuthCodeToMessage} from "../firebase";
import {NotFoundError, DuplicationError} from "../components/SWipeAppErrors";
import moment from "moment/moment";
import {captureMessage} from "@sentry/react";
import * as Sentry from "@sentry/react";
import {useNavigate} from "react-router-dom";
import User from "../models/User";

const UserAuthContext = createContext();

export function UserAuthContextProvider({children}) {

    const localStorageUser = sessionStorage.getItem("user");
    const localStorageUserData = sessionStorage.getItem("userData");
    const localStorageUserTests = sessionStorage.getItem("userTests");
    const [user, setUser] = useState(localStorageUser ? JSON.parse(localStorageUser) : null);
    const [userData, setUserData] = useState(localStorageUserData ? JSON.parse(localStorageUserData) : {});
    const [userTests, setUserTests] = useState(localStorageUserTests ? JSON.parse(localStorageUserTests) : []);
    const navigate = useNavigate();

    console.log('Current user:', auth.currentUser?.uid);

    Sentry.setUser(auth.currentUser ? {
        id: auth.currentUser.uid, email: auth.currentUser.email
    } : null);

    // useEffect(() => {
    //     if (!user) {
    //         console.log('Clearing local storage');
    //         localStorage.removeItem("user");
    //     } else {
    //         console.log('Updating local storage');
    //         localStorage.setItem("userData", JSON.stringify(user));
    //     }
    // }, [user])

    async function updateUserData(...keyValuePairs) {
        if (userData === undefined) {
            console.log('Cannot update user data');
            return false;
        }

        for (let i = 0; i < keyValuePairs.length; i += 2) {
            userData[keyValuePairs[i]] = keyValuePairs[i + 1];
        }

        try {
            captureMessage("Updating user data.");
            await setDoc(doc(db, "users", user.uid), userData, {merge: true});
        } catch (error) {
            console.error(error);
            return false;
        }

        return true;
    }

    async function updateScanData(barcode, ...keyValuePairs) {
        if (barcode === null) return false;

        let data = {};
        userTests.forEach((test) => {
            if (test.barcode === barcode) {
                data = test;
            }
        });
        for (let i = 0; i < keyValuePairs.length; i += 2) {
            data[keyValuePairs[i]] = keyValuePairs[i + 1];
        }

        captureMessage("Updating user tests.");
        const testRef = doc(db, "users", user.uid, "tests", barcode);
        await updateDoc(testRef, data)
            .catch((e) => {
                if (e.code === 'not-found') {
                    setDoc(testRef, data);
                    setUserTests([...userTests, data]);
                } else {
                    console.error(e);
                    throw e;
                }
            });

        return true;
    }

    function getLastTestExcept(barcode) {
        if (!userTests || userTests.length <= 1) return null;

        const sortedTests = userTests.filter((test) => test.barcode !== barcode);
        sortedTests.sort((a, b) => b.date - a.date);
        return sortedTests[0]
    }

    async function checkFields() {
        return await getDoc(doc(db, "users", user.uid))
            .then((snapshot) => {
                const userData = snapshot.data();
                const userModel = new User(user.uid, userData);
                return userModel.checkFields();
            });
        // return ['gender', 'race', 'dateOfBirth', 'weight', 'weightUnit', 'height', 'heightUnit', 'diets']
        //     .every(key => userData.hasOwnProperty(key));
    }

    function checkUserData(userData) {
        const userModel = new User(userData);
        return userModel.checkFields();
        // return ['gender', 'race', 'dateOfBirth', 'weight', 'weightUnit', 'height', 'heightUnit', 'diets']
        //     .every(key => userData.hasOwnProperty(key));
    }

    function logIn(email, password) {
        return signInWithEmailAndPassword(auth, email, password);
    }

    function signUp(email, firstName, lastName, password, reference) {
        return createUserWithEmailAndPassword(auth, email, password)
            .then(async (response) => {
                const userId = response.user.uid;
                try {
                    captureMessage("Registering a user.");
                    const data = {
                        firstName: firstName,
                        lastName: lastName,
                        email: email
                    }
                    if (reference) {
                        data['reference'] = reference;
                    }
                    await setDoc(doc(db, "users", userId), data)
                        .then(() => {
                            setUserData(userData);
                            localStorage.setItem("user", JSON.stringify(userData));
                        });
                } catch (error) {
                    console.error(error);
                    throw error;
                }

                verifyEmail(response.user);
            });
    }

    function verifyEmail(user) {
        const actionCodeSettings = {
            url: 'https://s-wipe.io'
        }

        if (!auth.currentUser) {
            navigate('/login');
            return;
        }

        sendEmailVerification(auth.currentUser, actionCodeSettings)
            .then(() => {
                alert("Verification email sent. Please confirm your email to continue using S-Wipe app.");
            })
            .catch((error) => {
                console.log(error);
                const message = mapAuthCodeToMessage(error.code);
                if (message !== undefined) {
                    alert(message);
                } else {
                    throw error;
                }
            });
        // auth.signOut();
    }

    async function resetPassword(email) {
        await sendPasswordResetEmail(auth, email);
        console.log(`Password reset sent to ${email}`);
    }

    async function verifyBarcode(barcode) {
        if (barcode.length === 0)
            throw new NotFoundError("Barcode is missing");

        // Check that the barcode is in the database
        const barcodeRef = doc(db, "barcodes", barcode);
        const barcodeSnapshot = await getDoc(barcodeRef);
        captureMessage("Reading a barcode");
        if (!barcodeSnapshot.exists())
            throw new NotFoundError("Barcode " + barcode + " not found!");

        // Check that the barcode has not been previously scanned
        const scannedBarcodeReference = doc(db, "users", user.uid, "tests", barcode);
        const scannedBarcodeSnapshot = await getDoc(scannedBarcodeReference);
        captureMessage("Reading a barcode");
        if (scannedBarcodeSnapshot.exists()) {
            const date = moment(scannedBarcodeSnapshot.data().date.toDate()).format('MMM DD, YYYY')
            throw new DuplicationError(`Barcode ${barcode} was already scanned on ${date}.`);
        }
    }

    function logOut() {
        return signOut(auth).then(() => {
            sessionStorage.removeItem("user");
            setUser(null);
            sessionStorage.removeItem("userData");
            setUserData({});
            sessionStorage.removeItem("userTests");
            setUserTests([]);
        });
    }

    async function countDocuments(collectionName) {
        const coll = collection(db, collectionName);
        const snapshot = await getCountFromServer(coll);
        return snapshot.data().count;
    }

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
            // if (currentUser !== user) {
            console.log("Auth", currentUser?.uid);
            captureMessage("Changing user.", {
                "user": {"id": currentUser?.uid}
            });
            await currentUser?.getIdTokenResult()
                .then(idTokenResult => {
                    currentUser['isAdmin'] = !!idTokenResult.claims.admin;
                });

            if (!!currentUser) {
                const user = {
                    uid: currentUser.uid,
                    firstName: currentUser.firstName,
                    lastName: currentUser.lastName,
                    email: currentUser.email,
                    emailVerified: currentUser.emailVerified,
                    isAdmin: !!currentUser.isAdmin,
                }
                sessionStorage.setItem("user", JSON.stringify(user));
                setUser(user);

                getDoc(doc(db, "users", currentUser.uid))
                    .then((doc) => {
                        if (doc.exists()) {
                            const data = doc.data();
                            captureMessage("Reading user data.");
                            sessionStorage.setItem("userData", JSON.stringify(data));
                            setUserData(data);
                            if (!checkUserData(data)) {
                                navigate("/account/details");
                            }

                        } else {
                            console.log("User document doesn't exist");
                            captureMessage("User document doesn't exist", "warning");
                            sessionStorage.removeItem("userData");
                            setUserData({});
                        }
                    })
                    .catch((error) => {
                        console.error('Error fetching document:', error);
                        throw error;
                    });

                getDocs(collection(db, "users", currentUser.uid, "tests"))
                    .then((querySnapshot) => {
                        const tests = [];
                        querySnapshot.forEach(
                            (x) => {
                                tests.push(x.data());
                                captureMessage("Reading user test.");
                            });
                        sessionStorage.setItem("userTests", JSON.stringify(tests));
                        setUserTests(tests);
                    });

            } else {
                sessionStorage.removeItem("user");
                setUser(null);
                sessionStorage.removeItem("userData");
                setUserData({});
                sessionStorage.removeItem("userTests");
                setUserTests([]);
                Sentry.setUser(null);
            }
            // }
        });

        return () => unsubscribe();
    }, [])

    return (
        <UserAuthContext.Provider value={{
            auth, user, userData, userTests, getLastTestExcept, logIn, signUp, logOut, updateUserData, checkFields,
            updateScanData, verifyEmail, verifyBarcode, resetPassword, countDocuments
        }}>
            {children}
        </UserAuthContext.Provider>
    )
}

export function useUserAuth() {
    return useContext(UserAuthContext);
}