import {Stack, ToggleButton} from "react-bootstrap";
import Button from "react-bootstrap/Button";
import {useUserAuth} from "../../../contexts/UserAuthContext";
import {useState} from "react";

const Constipation = ({prev, next}) => {
    const {updateUserData, userData} = useUserAuth();

    const [constipation, setConstipation] = useState(userData.constipation || false);

    function handleClick() {
        updateUserData("constipation", constipation)
            .then(success => {
                if (success) {
                    next();
                }
            });
    }

    return (
        <>
            <Stack className="justify-content-center" gap={3}>
                <h3 className="flex-grow-1">Do you experience constipation?</h3>
                <p>
                    Common symptoms:
                </p>
                <ul className="text-start">
                    <li>Fewer than three (3) stools a week</li>
                    <li>Stool hardness</li>
                    <li>Straining or pain</li>
                    <li>Unfinished feeling</li>
                    <li>Rectum block</li>
                </ul>

                <ToggleButton id="no" value="no" className="rounded-5" type="checkbox" variant="outline-dark"
                              checked={!constipation}
                              onChange={() => {
                                  setConstipation(false);
                              }}>
                    No
                </ToggleButton>
                <ToggleButton id="yes" value="yes" className="rounded-5" type="checkbox" variant="outline-dark"
                              checked={constipation}
                              onChange={() => {
                                  setConstipation(true);
                              }}>

                    Yes
                </ToggleButton>
                <span className="flex-grow-1"/>
            </Stack>
            <Stack direction="horizontal" gap={3}>
                <Button className="w-50" variant="secondary" onClick={prev}>Previous</Button>
                <Button className="w-50" onClick={handleClick}
                        disabled={constipation === undefined || constipation === null}>
                    Next
                </Button>
            </Stack>
        </>
    )
}

export default Constipation;